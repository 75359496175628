.container {
  flex: 1;
  display: flex;
}

.text {
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 8px;
}

.input {
  flex: 1;
  margin-right: 8px;
}

.input:last-child {
  margin-right: 0;
}
