.row {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
};

.row:last-child {
  margin-bottom: 0;
}

.item {
  margin-right: 16px;
}

.item:last-child {
  margin-right: 0;
}

.item-flex {
  flex: 1;
  margin-right: 16px;
}

.item-flex:last-child {
  margin-right: 0;
}

.button-container {
  display: flex;
  align-items: center;
  height: 32px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px !important;
  height: 16px;
  padding: 0;
  border-radius: 4px;
}

.select {
  margin-right: 0;
}
