.container {
  display: flex;
}

.label-container {
  display: flex;
  align-items: center;
  height: 32px;
}

.input-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.input-label {
  margin-right: 8px;
}

.ant-input {

}

.ant-input-blue {
  color: #1677FF;
}

.message-container {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}

.message {
  color: #ff4d4f
}
