.text {
    color: hsl(212, 100%, 14%);
    font-size: 24px;
}

.word {
    display: inline-block;
    opacity: 0;
    position: relative;
    padding: 4px 8px;
    margin-right: 8px;
    white-space: nowrap;
    user-select: none;
    z-index: 0;
}

.word:hover {
    opacity: 1;
    color: hsl(212, 100%, 60%);
}

.word::after {
    content: '';
    position: absolute;
    top: 0;
    left: -2px; // Recentering for border below.
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0;
}

.word:hover::after {
    border: 2px solid hsl(212, 100%, 40%);
    border-radius: 6px;
    animation-name: highlight;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;

    /* offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: 0px 0px 12px 6px hsl(212, 100%, 20%);
}

@keyframes highlight {
    0% {
        opacity: 0;
        width: 0%;
    }

    35% {
        opacity: 1;
    }

    45% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        width: 100%;
    }
}
