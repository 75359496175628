.container {
    display: flex;
    flex-direction: column;
}

.container-collapsed {
    display: none;
}

.option {
    border-radius: 6px;
    color: hsl(0, 0%, 65%);
    padding: 4px 12px;
    cursor: pointer;
}

.option:hover {
    color: hsl(0, 0%, 85%);
    background-color: #003166;
}

.skeleton {
    margin-bottom: 4px;
}

.skeleton:last-child {
    margin-bottom: 0;
}
