.modal {

}

.inner-container {
    max-height: 700px;
    overflow: auto;
}

.message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 0px;
}
