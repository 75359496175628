.container {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    padding: 4px;
    margin-bottom: 4px;
    overflow: hidden;
    border-radius: 6px;
    color: hsl(0, 0%, 65%);
}

.header:hover {
    color: hsl(0, 0%, 85%);
}

.header-active {
    background-color: #003166;
    color: hsl(0, 0%, 85%);
}

.left {
    flex: 1;
    display: flex;
    cursor: pointer;
    align-items: center;
    overflow: hidden;
}

.icon-container {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}

.icon-margin-right-16 {
    margin-right: 16px;
}

.icon-margin-right-16:last-child {
    margin-right: 0px;
}

.icon {
    font-size: 20px;
    color: inherit;
}

.title {
    flex: 1;
    // max-width: 144px;
    color: inherit;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.right {
    display: flex;
    align-items: center;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 8px;
}

.content-closed {
    display: none;
}

.caret-container {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.caret {
    font-size: 12px;
    color: inherit;
    transition-property: transform;
    transition-duration: 0.25s;
}

.caret-open {
    transform: rotateX(180deg);
}

.more-container {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.more {
    font-size: 20px;
    color: inherit;
}
