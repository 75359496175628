.model-settings {
    display: flex;
    flex-direction: column;
    max-width: 896px;
    padding: 16px;
}

.card {
    &__title {
        font-size: 16px;
        font-weight: var(--text_font_weight_medium);
    }
}

.form {
    &__script_field {
        border-radius: 8px;
        font-size: 14px;
        background-color: #f9f9f9;
        border: 1px solid #d4d4d4;
    }
}
