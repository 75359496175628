.container {
    flex: 1;
    width: 296px;
    background-color: var(--primery_dark_blue);
    display: flex;
    flex-direction: column;
    transition-property: width;
    transition-duration: 0.2s;
    overflow: hidden;
}

.container-collapsed {
    width: 64px;
}

.top {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0 16px;
}

.logo-collapsed {
    justify-content: center;
}

.image {
    width: 128px;
    cursor: pointer;
}

.image-collapsed {
    width: 27px;
    cursor: pointer;
}

.icon-container {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: hsl(0, 0%, 65%);
    cursor: pointer;
}

.icon-container:hover {
    color: hsl(0, 0%, 85%);
}

.icon {
    font-size: 20px;
    color: inherit;
}

.center {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 16px 12px 0 12px;
}

.pages {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.trees {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: none;
}

.trees::-webkit-scrollbar {
    width: 0px;
}

.bottom {
    display: flex;
    flex-direction: column;
}
