@import '~@fluentui/react/dist/sass/References';

.custom-doc-label-page {
    height: 100%;

    .label-page-header {
        padding: 10px 0 15px;
    }

    .label-page-main {
        border-top: 1px solid $ms-color-gray30;

        .result-view-box {

            padding: 0 10px 0 0;
        }

        .label-page-gallery {
            flex: 0 180px;

            .upload-button {
                margin: 8px 16px 8px;
            }
        }

        .label-page-canvas {
            border-left: 1px solid $ms-color-gray30;
            border-right: 1px solid $ms-color-gray30;
        }
    }
}

.json-child {
    border-left: 1px #eee solid;
    padding-left: 16px;
}

.json-pointer:hover {
    cursor: pointer;
}

.upload-button {
    margin-top: 8px;
}
