.container {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.sidebar {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.right {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.header {
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid #eeeeee;
}

.heading {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
