.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.heading {
  margin-bottom: 12px;
}

.row {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.row:last-child {
  margin-bottom: 0;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.text {
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 8px;
}

.button-container {
  display: flex;
}

.order-button-container {
  display: flex;
  margin-right: 16px;
  color: hsl(0, 0%, 45%);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px !important;
  height: 16px;
  padding: 0;
  border-radius: 4px;
  margin-right: 4px;
}

.button:last-child {
  margin-right: 0;
}
