@import '~@fluentui/react/dist/sass/References';

.label-item {
    padding: 5px 8px;
    color: $ms-color-gray130;

    &:hover {
        cursor: pointer;
        background-color: $ms-color-gray20;
    }

    .label-item-entry {
        display: grid;
        grid-template-columns: 24px 24px 1fr 24px;
        align-items: center;
    }

    .label-item-entry-with-confidence {
        display: grid;
        grid-template-columns: 24px 1fr 24px 24px;
        align-items: center;
    }

    .label-item-entry-table {
        display: grid;
        grid-template-columns: 24px 1fr 24px;
        align-items: center;
    }

    .label-item-result {
        display: grid;
        grid-template-columns: 24px 1fr 24px;
        align-items: center;
    }

    .label-item-value {
        padding: 6px 0;
        cursor: pointer;

        .label-item-text {
            font-size: 14px;
            font-weight: 600;
        }
    }

    .label-item-icon {
        font-size: 16px;
    }

    .hide-drag-icon {
        display: none;
    }

    .no-select {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }
    .label-item-array{
        margin-bottom: 14px;
    }

    .table-container {

        display: flex;
        flex-direction: column;

        cursor: default;

        .table-row {

            display: flex;
            flex-direction: column;
            margin-bottom: 16px;

            .table-row-heading {
                margin-bottom: 8px;
            }

            .table-row-word {

                display: flex;
                justify-content: space-between;

                padding-left: 16px;
                padding-right: 8px;
                padding-bottom: 16px;

                color: rgb(50, 49, 48);

                .table-row-word-left {

                    .table-row-word-column-name {

                    }

                    .table-row-word-content {

                    }
                }

                .table-row-word-right {

                }
            }

            .table-row-word:last-child {
                padding-bottom: 0;
            }
        }

        .table-row:first-child {
            margin-top: 16px;
        }
    }
}
