.f-width {
    width: 100% !important;
}

.f-height {
    height: 100% !important;
}

// Spacing padding
.pl-1 {
    padding-left: 8px;
}

.pl-2 {
    padding-left: 16px;
}

.pr-1 {
    padding-right: 8px;
}

.pr-2 {
    padding-right: 16px;
}

.mt-1 {
    margin-top: 8px;
}

.mt-2 {
    margin-top: 16px;
}

.mt-4 {
    margin-top: 32px;
}

.mt-8 {
    margin-top: 64px;
}

.mb-1 {
    margin-bottom: 8px;
}

.mb-2 {
    margin-bottom: 16px;
}

// Display
.d-flex {
    display: flex;
}

.d-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

// Background
.bg-primary {
    background-color: var(--primery_light_blue);
}

//Border
.br-primary {
    border-radius: 8px;
}
