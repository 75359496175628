.container {
    display: flex;
    color: hsl(0, 0%, 65%);
    margin-bottom: 4px;
    border-radius: 6px;
    padding: 4px 4px;
    transition-property: background-color;
    transition-duration: 0.25s;
    position: relative;
    cursor: pointer;
}

.container:last-child {
    margin-bottom: 0;
}

.container:hover {
    background-color: #003166;
    color: hsl(0, 0%, 85%);
}

.container-active {
    background-color: #003166;
    color: hsl(0, 0%, 85%);
}

.icon-container {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}

.icon-container-collapsed {
    margin-right: 0px;
}

.icon {
    font-size: 20px;
}

.text-container {
    display: flex;
    align-items: center;
    color: inherit;
    visibility: visible;
    position: static;
}

.text-container-collapsed {
    visibility: hidden;
    position: absolute;
    top: 0;
}

.text {
    color: inherit;
}
