.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: hsl(212, 100%, 13%);
}

.card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    max-width: 400px;

    /* offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 2;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: saturate(180%) blur(10px);
}

.top {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: hsl(212, 100%, 15%);
    padding: 32px 0;
}

.bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
}

.logo {
    width: 224px;
}

.heading {
    color: white;
    font-size: 24px;
    line-height: 32px;
    font-weight: var(--text_font_weight_semi);
    margin-bottom: 32px;
}

.subheading {
    margin-bottom: 32px;
}

.button {
}

.terms {
    color: white;
    margin-bottom: 32px;
}

.link {
    display: flex;
    justify-content: center;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    padding: 8px 32px;
    display: flex;
    align-items: center;
}
