@import '~@fluentui/react/dist/sass/References';

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: inherit;

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 16px 24px 20px;

        .modal-header-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
        }
    }

    .modal-body {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        padding: 0 24px;
        min-height: 0;
    }

    .modal-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;

        .modal-button-group {
            display: flex;
            gap: 8px;
        }
    }

    .modal-loading {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);

        .modal-spinner {
            padding: 0;
            height: 100%;
        }
    }
}
