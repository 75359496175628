.container {
    display: flex;
    padding: 16px;
    color: hsl(0, 0%, 65%);
    cursor: pointer;
}

.left {
    display: flex;
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 24px;
    background-color: #0085e5;
    margin-right: 16px;
}

.avatar-collapsed {
    margin-right: 0px;
}

.name {
    flex: 1;
    display: flex;
    align-items: center;
}

.text {
    color: inherit;
}

.initial {
    color: hsl(0, 0%, 85%);
}

.right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: hsl(0, 0%, 65%);
}

.icon:hover {
    color: hsl(0, 0%, 85%);
}

.more {
    font-size: 20px;
}

.menu {
    color: hsl(0, 0%, 65%);
    cursor: pointer;
}

.menu:hover {
    color: hsl(0, 0%, 85%);
}

.menu-item {
    border-radius: 6px;
    padding: 4px 12px;
    color: inherit;
}

.menu-item:hover {
    background-color: #003166;
}
