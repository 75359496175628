.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.container-collapsed {
  height: 40px;
  width: 40px;
  margin-bottom: 16px;
}

.text {
  color: hsl(0, 0%, 45%);
}

.skeleton {
  margin-bottom: 4px;
}

.skeleton:last-child {
  margin-bottom: 0;
}

.dropdown {
  color: hsl(0, 0%, 85%);
  background-color: #003166;
  border-color: #003166;
}

.icon {
  font-size: 12px;
  color: hsl(0, 0%, 45%);
  margin-left: 8px;
}
