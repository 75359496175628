.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.row:last-child {
  margin-bottom: 0;
}

.footer-button {
  margin-left: 16px;
}

.footer-button:first-child {
  margin-left: 0;
}

.table-container {
  margin-bottom: 16px;
}

.table-scrollable-container {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  min-height: 0;
  height: 640px;
  overflow: auto;

  .table-view {
    border-collapse: collapse;

    td,
    th {
      border: 2px solid #8d8d8d;
      max-width: 400px;
      padding: 0.5rem 1rem;
    }

    th {
      background-color: #FAF9F8;
    }

    td:empty::after {
      content: '\00a0';
    }
  }
}
