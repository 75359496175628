.modal-row-item-16 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.modal-row-item-16:last-child {
  margin-bottom: 0;
}

.modal-row-item-12 {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.modal-row-item-12:last-child {
  margin-bottom: 0;
}

.modal-ruleset-name-input {
  display: flex;
  width: 384px;
}

.modal-rules-container {
  flex: 1;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.modal-padding-left-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 12px;
}

.modal-padding-left-container:last-child {
  margin-bottom: 0;
}

.modal-button-small {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px !important;
  height: 16px;
  padding: 0;
  border-radius: 4px;
}

.modal-reload-button {
  color: hsl(0, 0%, 45%);
}

.modal-footer-row {
  display: flex;
  justify-content: space-between;
}

.modal-footer-row-button {
  margin-right: 16px;
  margin-inline-start: 0 !important;
}

.modal-footer-row-button:last-child {
  margin-right: 0;
}

.modal-footer-row-left {

}

.modal-footer-row-right {

}

.modal-errors-container {

}

.modal-errors-row {
  margin-bottom: 4px;
  color: #ff4d4f;
}

.modal-errors-row:last-child {
  margin-bottom: 0;
}

.modal-retry-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-retry-icon {
  background-color: #ff4d4f;
}
