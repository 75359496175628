.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}

.container:last-child {
  margin-bottom: 0;
}

.name-container {
  display: flex;
  align-items: center;
  padding: 0 6px;
  border-radius: 4px;
  background-color: #0085e5;
  color: white;
}

.check-icon {
  font-size: 20px;
  color: rgb(115, 115, 115);
  margin-right: 8px;
}

.name {

}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px !important;
  height: 16px;
  padding: 0;
  border-radius: 4px;
  margin-right: 4px;
}

.button:last-child {
  margin-right: 0;
}

.button-container {
  display: flex;
}

.order-button-container {
  display: flex;
  margin-right: 16px;
  color: hsl(0, 0%, 45%);
}

.row {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.row:last-child {
  margin-bottom: 0;
}

.row-left {
  flex: 1;
  display: flex;
  // align-items: center;
}

.row-text-container {
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 8px;
}

.row-text {
  margin-right: 8px;
}

.row-dropdown {
  min-width: 128px;
  margin-right: 8px;
}

.row-dropdown:last-child {
  margin-right: 0;
}

.row-text:last-child {
  margin-right: 0;
}

.input {
  flex: 1;
  margin-right: 8px;
}

.input:last-child {
  margin-right: 0;
}
