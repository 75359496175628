:root {
    // TEXT VARIABLES
    --text_font_weight_regular: 400;
    --text_font_weight_medium: 500;
    --text_font_weight_semi: 600;
    --text_font_size: 16px;
    --text_line_height: 1.1;
    --text_color_light_gray: #ffffff99;

    // COLOR VARIABLES
    --white_color: #ffffff;
    --error_color: #ff4d4f;
    --primery_dark_blue: #001f42;
    --primery_light_blue: #003166;
}
