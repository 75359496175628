.container {
    padding: 16px;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 1px solid #EEEEEE;
    border-radius: 8px;
    padding: 24px;
}

.top {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.title {
    font-size: 16px;
    font-weight: 500;
    margin-right: 16px;
}

.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
}

.empty-text {
    color: #ccc;
}

.drawer {
    margin-bottom: 12px;
}
