@import '~@fluentui/react/dist/sass/References';

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

#root {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.loading {
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
}

.react-split-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .gutter {
        background-color: $ms-color-gray10;
        border-right: 1px solid $ms-color-gray30;
        background-repeat: no-repeat;
        background-position: 50%;
    }

    .gutter.gutter-horizontal {
        cursor: col-resize;
    }
}

.sr-only {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
