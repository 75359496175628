.row {
  flex: 1;
  display: flex;
  margin-bottom: 12px;
};

.row:last-child {
  margin-bottom: 0;
}

.item {
  margin-right: 16px;
}

.item:last-child {
  margin-right: 0;
}

.item-flex {
  flex: 1;
  margin-right: 16px;
}

.item-flex:last-child {
  margin-right: 0;
}

.select {
  margin-right: 0;
}
