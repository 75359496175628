.upload_modal {
    padding: 32px;

    &__icon {
        & > svg {
            fill: #00000073;
            width: 30px;
            height: 30px;
        }
    }

    &__content {
        padding: 32px;
    }

    &__text {
        padding: 0 16px;
        color: #00000073 !important;

        & > span {
            color: #0085e5 !important;
        }
    }

    & :global(.ant-upload-list.ant-upload-list-text) {
        border-top: 1px solid #eeeeee;
        margin-top: 32px;
        padding: 24px 0 0;
    }
}

.upload_list {
}
