.container {
  display: flex;
  flex-direction: column;
  flex: 1,
}

.top {
  display: flex;
  flex-direction: column;
}

.bottom {
  display: flex;
  flex-direction: column;
}

.row {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.row:last-child {
  margin-bottom: 0;
}

.row-left {
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.row-left:last-child {
  margin-bottom: 0;
}

.row-margin-bottom {
  margin-bottom: 12px;
}

.text {
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 8px;
}

.button-container {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px !important;
  height: 16px;
  padding: 0;
  border-radius: 4px;
  margin-right: 4px;
}

.input {
  flex: 1;
  margin-right: 8px;
}

.input:last-child {
  margin-right: 0;
}
