.ant-col.ant-form-item-label {
    padding-bottom: 0;
}

.ant-form-item {
    margin-bottom: 16px;
}

input,
textarea {
    &::placeholder {
        font-size: 14px;
    }
}
