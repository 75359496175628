.heading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 8px;
}

.heading {
    font-size: 16px;
    font-weight: var(--text_font_weight_medium);
}

.sub-heading {
    font-size: 16px;
    font-weight: var(--text_font_weight_medium);
    margin-bottom: 24px;
}

.section {
    border-radius: 8px;
    font-size: 14px;
    border: 1px solid #d4d4d4;
    padding: 16px;
    margin-bottom: 16px;
}

.section:last-child {
    margin-bottom: 0;
}

.section-heading-container {
    margin-bottom: 24px;
}

.section-heading-tag {
    font-size: 14px;
    font-weight: var(--text_font_weight_medium);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.section-heading-text {
    font-size: 14px;
    font-weight: var(--text_font_weight_medium);
}

.info {

}

.details-container {
    margin-bottom: 24px;
}

.details-container:last-child {
    margin-bottom: 0;
}

.details-heading {
    font-weight: var(--text_font_weight_medium);
    margin-bottom: 4px;
}

.details {

}

.detail {
    margin-bottom: 4px;
}

.detail-title {
    margin-right: 12px;
}

.detail-text {
    border-radius: 4px;
    font-size: 12px;
    color: #686868;
    background-color: #f9f9f9;
    border: 1px solid #d4d4d4;
    padding: 2px 6px;
}

.button-container {
    margin-top: 32px;
}
